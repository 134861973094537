import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    display: "none",
    "&$checked": {
      display: "flex",
      color: "#2DBE78",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function PaymentMethod(props) {
  const [payments, setPayments] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState("0");

  const handleChange = (event) => {
    setState({
      ...defaultValue,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSetPaymentMethod = (payment) => {
    props.handleSelectedPayment(payment);
    props.setDisplayCard();
  };

  // const handleClick = (dataName, dataValue) => {
  //   if (!dataValue) {
  //     props.handleSelectedPayment(null);
  //     props.setDisplayCard();
  //   } else {
  //     props.handleSelectedPayment(dataName);
  //     props.setDisplayCard();
  //   }
  //   setState({
  //     ...defaultValue,
  //     [dataName]: dataValue,
  //   });
  // };

  return (
    <Grid style={{ padding: "35px 20px" }}>
      <Grid
        onClick={() => props.setDisplayCard()}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <ArrowBackIcon />
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginLeft: 8,
          }}
        >
          Kembali
        </Typography>
      </Grid>

      <Grid>
        <Grid style={{ padding: "0px 12px", overflowY: "scroll", height: 450 }}>
          {props.availablePayments.length < 1 && (
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                paddingLeft: "1rem",
                marginTop: 25,
              }}
            >
              Tidak Ada Metode Pembayaran Tersedia
            </Typography>
          )}
          {props.availablePayments.map((p) => (
            <Grid
              key={p.id}
              onClick={() => {
                if (p.isAvailable) handleSetPaymentMethod(p);
              }}
              style={{
                display: "flex",
                width: "100%",
                padding: "18px 0px",
                alignItems: "center",
                borderBottom: "1px solid #CCCCCC",
                cursor: `${p.isAvailable ? "pointer" : "default"}`,
              }}
            >
              <div
                item
                xs={6}
                style={{
                  display: "flex",
                  opacity: `${!p.isAvailable ? "0.3" : "1"}`,
                }}
              >
                <div
                  style={{
                    width: "65px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img src={p.imageUrl} style={{ width: "inherit" }} />
                </div>
                <div>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: "1rem",
                    }}
                  >
                    {p?.label || p?.name}
                  </Typography>
                  {!p.isAvailable && (
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 400,
                        paddingLeft: "1rem",
                        fontWeight: "700",
                      }}
                    >
                      {p.message}
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentMethod;
